import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Input } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import io from "socket.io-client";
import { Snackbar } from '@mui/material';
const HFTPage = () => {

  if (process.env.NODE_ENV === 'production') {
    URL = "https://www.multiverse-funding.com";
  } else {
    URL = "https://www.multiverse-funding.com";
    // URL = "http://localhost:8080";
  }
  let SOCKET_URL;
  if (process.env.NODE_ENV !== 'development') {
    SOCKET_URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  } else {
    // SOCKET_URL = "http://localhost:3000";
    SOCKET_URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  }
  const [accountNumber, setAccountNumber] = useState('');
  const [indice, setIndice] = useState('');
  const [lotSize, setLotSize] = useState(0);
  const [positions, setPositions] = useState([]);
  const [running, setRunning] = useState(false);
  const [Details, setDetails] = useState(null)
  const [US30, setUS30] = useState(null)

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [isRunning, setIsRunning] = useState(false); // State to track if the process is running
  const [intervalId, setIntervalId] = useState(null); // Store interval ID to stop it later

  const handleLoadAccount = () => {
    if (accountNumber !== "") {
      axios.post(`${URL}/api/find-trading-acccount`, {
        accountId: accountNumber
      })
        .then(res => {
          setDetails(res.data)
          fetchDataAndSetupSocket(res.data.accountId);
        })
        .catch(err => {
          console.log(err)
        })
    }
  };

  const [formData, setFormData] = useState({
    symbol: 'US30',
    takeProfit: '',
    stopLoss: '',
    action: '',
    lot: '',
    accountId: '',
    entryPrice: '',
  });

  // Function to execute a sell order
  const executeSell = (price) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      action: 'Sell',
      entryPrice: price,
      lot: lotSize,
      accountId: accountNumber,
    }));
    handleSubmit(); // Submit the sell order
  };

  // Function to execute a buy order
  const executeBuy = (price) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      action: 'Buy',
      entryPrice: price, // Set the entry price as the current price
    }));
    handleSubmit(); // Submit the buy order
  };

  // Function to stop the process
  const handleStop = () => {
    setIsRunning(false);
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval
      setIntervalId(null); // Reset the interval ID
    }
  };

  // Function to start the process (runs every 5 seconds)
  const handleStart = () => {
    if (!isRunning) {
      setIsRunning(true);
      const id = setInterval(() => {
        console.log('running')
        if (US30) {
          executeSell(US30.bid + 10);
        }
      }, 5000); // Repeat every 5 seconds
      setIntervalId(id); // Save interval ID so we can stop it later
    }
  };

  // Submit the order using an API call
  const handleSubmit = async () => {

    try {
      const response = await axios.post(`${URL}/api/add-position`, formData);
      console.log('Response:', response);

      setSnackbarMessage('Position submitted successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error submitting position:', error);

      setSnackbarMessage('Failed to submit position.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };
 // Snackbar close handler
 const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};
  // Listening for the 'welcome' event from the server
  // socket.on('userLivedata', (data) => {

  // });

  // START THE WEBSOCKET TO GET LIVE DATA
  const fetchDataAndSetupSocket = async (id) => {
    const socket = io(`${SOCKET_URL}/${id}`, {
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });
    socket.on('dataFeed', (data) => {
      setUS30(data.US30)
    });
    return () => socket.disconnect();
  };


  const textStyle = {
    fontFamily: 'Cabin, sans-serif',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    textAlign: 'center',
  };


  return (
    <Container>
      <Typography variant="h4" gutterBottom>HFT Passing</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleLoadAccount}>
            Load Account
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, height: 120, margin: 2 }}>
            <CardContent>
              <Typography color="text.secondary">
                <div style={textStyle} className='first_Container'>
                  <ul>
                    <li>Balance</li>
                    <li>Equity</li>
                  </ul>
                  <ul>
                    <li>{Details !== null ? formatCurrency(Details.CurrentBalance) : "0.00"}</li>
                    <li>{Details !== null ? formatCurrency(Details.equity) : "0.00"}</li>

                  </ul>
                </div>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Indice</InputLabel>
            <Select
              value={indice}
              onChange={(e) => setIndice(e.target.value)}
            >
              <MenuItem value="US30">US30</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Lot Size"
            value={lotSize}
            onChange={(e) => setLotSize(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            disabled={running}
          >
            Run HFT
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStop}
            disabled={!running}
            style={{ marginLeft: 10 }}
          >
            Stop HFT
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Opened Positions</Typography>
          {positions.map((position, index) => (
            <Typography key={index}>{`${position.indice} - ${position.lotSize} lots - ${position.tradeType} at ${position.timestamp}`}</Typography>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HFTPage;

function formatCurrency(price, currency = 'USD', locale = 'en-US') {
  return price.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2, // Ensures the decimal part is always shown
  });
}

const filledColor = 'blue'; // Green for filled part
const trackColor = 'rgba(217, 217, 217, 1)'; // White for the track (unfilled part)
function CircularProgressWithLabel({ value, filledColor, trackColor }) {
  return (
    <Box style={{ marginTop: "20px" }} position="relative" display="inline-flex" alignItems="center" justifyContent="center">
      {/* Track as background with 100% progress */}
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: trackColor, // Track color (unfilled part)
          position: 'absolute',
          zIndex: 1,
        }}
        size={80}
        style={{ marginTop: "20px" }}
      />
      {/* Actual progress */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={80}
        sx={{
          color: filledColor, // Filled part color
          position: 'absolute',
          zIndex: 2,
        }}
        style={{ marginTop: "20px" }}
      />
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={3} // Ensure the text is above both CircularProgress components
      >
        <Typography style={{ fontSize: "20px", color: "rgba(12, 11, 11, 1)", marginTop: "20px" }} variant="caption" component="div" color="text.secondary">
          {`${value.toFixed(1)}%`}
        </Typography>
      </Box>
    </Box>
  );
}